.writing-article, .writing-list {
  small.date {
    color: #999;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.writing-article small.date {
  display: block;
}