@import url('https://rsms.me/inter/inter.css');
@import "~reset-css/sass/_reset.scss";
@import "~sheng/sheng.scss";
@import "_lib";

@each $weight, $name in (400: "Regular", 500: "Medium", 900: "Black"){
    @font-face {
        font-family: "ISO";
        font-weight: $weight;
        font-style: normal;
        src: url("./ISO/ISOv0.6-#{$name}.eot") format("eot"),
             url("./ISO/ISOv0.6-#{$name}.woff") format("woff");
    }
}

html {
    font-size: 20px;
    font-family: Inter, sans-serif;
    color: #333;
    @media only screen and (max-width: 480px) {
        font-size: 16px;
    }
}

.text {
    @include sheng();
    max-width: $content-width;
    margin: 0 auto;
    padding: 0 1em;

    // TODO: make sheng support rem units
    @media only screen and (max-width: 480px) {
        @include sheng($base-font-size: 16px);
    }
}

@import "global";
@import "header";
@import "home";
@import "writing";
@import "projects";