// TODO add to Sheng

i, em {
  font-style: italic;
}

// Everything else

blockquote {
  padding-left: 20px;
  border-left: 10px solid #eee;
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: #999;
  text-decoration-thickness: 2px;
  text-decoration-skip: ink;

  &:hover, &:focus {
    text-decoration-color: $main;
  }
}