@import "_lib.scss";

.project-list {
  .link-bar {
    margin-top: 0.75em;
    a {
      color: $main;
      padding: 8px 20px;
      font-weight: 500;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }

      &:first-child {
        background-color: rgba($main, .1);
        border-radius: 5px;
        &:hover {
          background-color: rgba($main, .2);
          text-decoration: none;
        }
      }
    }
  }
}