@import "_lib";

header {
  width: $content-width;
  margin: 40px auto 66px auto;
  padding: 0 1em;
  font-family: ISO, monospace;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @media only screen and (max-width: 660px){
    width: auto;
    flex-direction: column;
    nav {
      justify-content: flex-end;
    }
  }
  
  h1 {
    .fn {
      font-weight: 900;
      font-size: 48px;
    }
    .sn {
      font-size: 16px;
      position: relative;
      top: -8px;
      left: 5px;
    }
  }

  #cursor {
    display: inline-block;
    width: 1.2ch;
    height: 0.95em;
    background-color: $main;
    margin-left: 3px;
    border-radius: 2px;
    pointer-events: none;
  }

  nav {
    text-align: right;
    line-height: 1.5;
    a {
      color: inherit;
      text-decoration: inherit;
      // there is an extra 1ch worth of whitespace too
      margin-left: 1ch;
      &.current, &:hover, &:focus {
        text-decoration: underline;
        text-decoration-color: $secondary;
        text-decoration-thickness: 2px;
        text-decoration-skip: ink;
      }
      &.current {
        text-decoration-color: $main;
      }
    }
    .secondary {
      color: #999;
    }
  }
}